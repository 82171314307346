import request from '@/utils/request'

export function findSubAreas(parentId) {
  return request({
    url: '/base/area/findSubAreas',
    method: 'get',
    params: {
      parentId
    }
  })
}

export function findAreaPage(data) {
  return request({
    url: '/base/area/findAreaPage',
    method: 'post',
    data
  })
}
