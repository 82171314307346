import request from '@/utils/request'

export function get(dictName) {
  const params = {
    dictName,
    page: 0,
    size: 9999
  }
  return request({
    url: '/base/sysDictDetail/dictDetail',
    method: 'get',
    params
  })
}
