<template>
  <el-dialog
    :visible.sync="dialog"
    :close-on-click-modal="false"
    :before-close="cancel"
    title="修改资料"
    append-to-body
    top="80px"
    width="1000px"
    class="app-client-dialog"
  >
    <el-form
      ref="updateForm"
      :inline="true"
      :model="updateForm"
      :rules="rules"
      size="small"
      label-width="130px"
      class="update-form"
    >
      <el-form-item label="姓名：" prop="realName">
        <div>{{ updateForm.realName }}</div>
      </el-form-item>
      <el-form-item label="证件类型：" prop="certType">
        {{ userCertType(updateForm.certType) }}
      </el-form-item>
      <el-form-item label="证件号：" prop="certNo">
        <div>{{ updateForm.certNo }}</div>
      </el-form-item>
      <el-form-item label="手机号：" prop="phoneNumber">
        <el-input v-model="updateForm.phoneNumber" />
      </el-form-item>
      <el-form-item label="邮箱：" prop="email">
        <el-input v-model="updateForm.email" />
      </el-form-item>
      <el-form-item label="注册公证处：" prop="notaryOfficeName">
        <div>{{ updateForm.notaryOfficeName }}</div>
      </el-form-item>
      <div v-if="updateForm.relatedClientFlag">
        <el-form-item label="联系地址：" prop="address" class="address-form">
          <el-select
            v-model="updateForm.contactAddressProvince"
            clearable
            filterable
            placeholder="省"
            style="width: 90px; content: ''"
          >
            <el-option v-for="item in provinces" :key="item.id" :label="item.areaName" :value="item.id" />
          </el-select>
          <el-select v-model="updateForm.contactAddressCity" clearable filterable placeholder="市" style="width: 90px">
            <el-option v-for="item in contactCities" :key="item.id" :label="item.areaName" :value="item.id" />
          </el-select>
          <el-select
            v-model="updateForm.contactAddressArea"
            clearable
            filterable
            placeholder="区/县"
            style="width: 90px"
          >
            <el-option v-for="item in contactDistricts" :key="item.id" :label="item.areaName" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="送达地址：" prop="address" class="address-form">
          <el-select
            v-model="updateForm.deliveryAddressProvince"
            clearable
            filterable
            placeholder="省"
            style="width: 90px"
          >
            <el-option v-for="item in provinces" :key="item.id" :label="item.areaName" :value="item.id" />
          </el-select>
          <el-select v-model="updateForm.deliveryAddressCity" clearable filterable placeholder="市" style="width: 90px">
            <el-option v-for="item in deliveryCities" :key="item.id" :label="item.areaName" :value="item.id" />
          </el-select>
          <el-select
            v-model="updateForm.deliveryAddressArea"
            clearable
            filterable
            placeholder="区/县"
            style="width: 90px"
          >
            <el-option v-for="item in deliveryDistricts" :key="item.id" :label="item.areaName" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="联系详细地址：" prop="email">
          <el-input v-model="updateForm.contactAddress" />
        </el-form-item>
        <el-form-item label="送达详细地址：" prop="email">
          <el-input v-model="updateForm.deliveryAddress" />
        </el-form-item>
        <el-form-item label="职业：" prop="profession">
          <el-input v-model="updateForm.profession" />
        </el-form-item>
      </div>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认修改</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { findSubAreas } from '@/api/area'
import { updateById } from '@/api/accountInfo'

export default {
  props: {
    userCertTypeDict: {
      default: () => [],
      type: Array
    }
  },
  data() {
    return {
      loading: false,
      dialog: false,
      value: '',
      updateForm: {
        id: null,
        realName: null,
        certType: null,
        certNo: null,
        phoneNumber: null,
        email: null,
        profession: null,
        contactAddressProvince: null,
        contactAddressCity: null,
        contactAddressArea: null,
        contactAddress: null,
        deliveryAddressProvince: null,
        deliveryAddressCity: null,
        deliveryAddressArea: null,
        deliveryAddress: null,
        notaryOfficeName: null,
        accountType: 2,
        relatedClientFlag: false
      },
      rules: {
        // paperType: [{ required: true, message: '请选择证件类型', trigger: 'blur' }],
        // email: [
        //   //{ required: true, message: '请输入邮箱地址', trigger: 'blur' },
        //   { type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' }
        // ]
      },
      //省
      provinces: [],
      //市
      contactCities: [],
      deliveryCities: [],
      //区/县
      contactDistricts: [],
      deliveryDistricts: []
    }
  },
  computed: {
    userCertType() {
      return (val) => {
        const data = this.userCertTypeDict.filter((item) => item.value == val)
        return data[0]?.label
      }
    }
  },
  watch: {
    'updateForm.contactAddressProvince'(val) {
      if (val) {
        findSubAreas(this.updateForm.contactAddressProvince)
          .then((res) => {
            this.contactCities = res.data
          })
          .catch(() => {})
      } else {
        this.contactCities = []
      }
    },
    'updateForm.contactAddressCity'(val) {
      if (val) {
        findSubAreas(this.updateForm.contactAddressCity)
          .then((res) => {
            this.contactDistricts = res.data
          })
          .catch(() => {})
      } else {
        this.contactDistricts = []
      }
    },
    'updateForm.deliveryAddressProvince'(val) {
      if (val) {
        findSubAreas(this.updateForm.deliveryAddressProvince)
          .then((res) => {
            this.deliveryCities = res.data
          })
          .catch(() => {})
      } else {
        this.deliveryCities = []
      }
    },
    'updateForm.deliveryAddressCity'(val) {
      if (val) {
        findSubAreas(this.updateForm.deliveryAddressCity)
          .then((res) => {
            this.deliveryDistricts = res.data
          })
          .catch(() => {})
      } else {
        this.deliveryDistricts = []
      }
    }
  },
  created() {
    findSubAreas(1)
      .then((res) => {
        this.provinces = res.data
      })
      .catch(() => {
        this.$notify({
          type: 'error',
          message: '加载省市数据失败'
        })
      })
  },
  methods: {
    cancel() {
      this.resetForm()
    },
    doSubmit() {
      this.$refs['updateForm'].validate((valid) => {
        if (!valid) {
          return
        } else {
          this.loading = true
          this.doSave()
        }
      })
    },
    doSave() {
      updateById({
        ...this.updateForm
      })
        .then((res) => {
          this.loading = false
          if (res.code !== 0) {
            this.$message.error(res.message)
          } else {
            this.dialog = false
            this.resetForm()
            this.$notify({
              title: '修改成功',
              type: 'success',
              duration: 2500
            })
            this.$parent.$parent.initData()
          }
        })
        .catch((err) => {
          this.loading = false
          console.error(err.response.data.message)
        })
    },
    resetForm() {
      this.dialog = false
      this.$refs['updateForm'].resetFields()
      this.updateForm = {
        id: null,
        realName: null,
        certType: null,
        certNo: null,
        phoneNumber: null,
        email: null,
        profession: null,
        contactAddressProvince: null,
        contactAddressCity: null,
        contactAddressArea: null,
        contactAddress: null,
        deliveryAddressProvince: null,
        deliveryAddressCity: null,
        deliveryAddressArea: null,
        deliveryAddress: null,
        notaryOfficeName: null,
        accountType: 2,
        relatedClientFlag: false
      }
    }
  }
}
</script>

<style>
.app-client-dialog .el-dialog__body {
  padding: 0px;
}
.app-client-wrapper {
  padding: 20px 40px;
  height: 520px;
  overflow: auto;
}
</style>
<style lang="scss" scoped>
.update-form .el-form-item {
  width: 49%;
}

.address-form {
  /deep/.el-select:not(:first-child) {
    margin-left: 5px;
  }
}
</style>
