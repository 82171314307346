<template>
  <div class="account-container">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>基本资料</span>
        <el-button style="float: right; padding: 3px 0" type="text" @click="updateInfo">修改</el-button>
      </div>
      <div>
        <el-form ref="clientInfoForm" :inline="true" :model="form" size="small" label-width="210px">
          <el-form-item label="名称：" prop="realName">
            <div>{{ form.account.realName }}</div>
          </el-form-item>
          <el-form-item label="证件类型：" prop="certType">
            <div>{{ userCertType(form.account.certType) }}</div>
          </el-form-item>
          <el-form-item label="证件号：" prop="certNo">
            <div>{{ form.account.certNo }}</div>
          </el-form-item>
          <el-form-item label="email：" prop="email">
            <div>{{ form.account.email }}</div>
          </el-form-item>
          <div v-if="form.account.accountType == 4">
            <el-form-item label="法定代表人姓名：" prop="legalPersonRealName">
              <div>{{ form.realNameVerify.legalPersonRealName }}</div>
            </el-form-item>
            <el-form-item label="法定代表人证件类型：" prop="legalPersonCertType">
              <div>{{ userCertType(form.realNameVerify.legalPersonCertType) }}</div>
            </el-form-item>
          </div>
          <div v-if="form.account.accountType == 4">
            <el-form-item label="法定代表人证件号：" prop="legalPersonCertNo">
              <div>{{ form.realNameVerify.legalPersonCertNo }}</div>
            </el-form-item>
            <el-form-item label="联系手机：" prop="phoneNumber">
              <div>{{ form.account.phoneNumber }}</div>
            </el-form-item>
          </div>
          <div v-if="form.account.accountType == 2">
            <el-form-item label="出生日期：" prop="birthday">
              <div>{{ form.client.birthday }}</div>
            </el-form-item>
            <el-form-item label="联系手机：" prop="phoneNumber">
              <div>{{ form.account.phoneNumber }}</div>
            </el-form-item>
          </div>
          <el-form-item label="联系地址：" class="address-form">
            <el-select
              v-model="form.client.contactAddressProvince"
              disabled
              clearable
              filterable
              placeholder="省"
              style="width: 110px"
            >
              <el-option v-for="item in provinces" :key="item.id" :label="item.areaName" :value="item.id" />
            </el-select>
            <el-select
              v-model="form.client.contactAddressCity"
              disabled
              clearable
              filterable
              placeholder="市"
              style="width: 110px"
            >
              <el-option v-for="item in contactCities" :key="item.id" :label="item.areaName" :value="item.id" />
            </el-select>
            <el-select
              v-model="form.client.contactAddressArea"
              disabled
              clearable
              filterable
              placeholder="区/县"
              style="width: 110px"
            >
              <el-option v-for="item in contactDistricts" :key="item.id" :label="item.areaName" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="送达地址：" class="address-form">
            <el-select
              v-model="form.client.deliveryAddressProvince"
              disabled
              clearable
              filterable
              placeholder="省"
              style="width: 110px"
            >
              <el-option v-for="item in provinces" :key="item.id" :label="item.areaName" :value="item.id" />
            </el-select>
            <el-select
              v-model="form.client.deliveryAddressCity"
              disabled
              clearable
              filterable
              placeholder="市"
              style="width: 110px"
            >
              <el-option v-for="item in deliveryCities" :key="item.id" :label="item.areaName" :value="item.id" />
            </el-select>
            <el-select
              v-model="form.client.deliveryAddressArea"
              disabled
              clearable
              filterable
              placeholder="区/县"
              style="width: 110px"
            >
              <el-option v-for="item in deliveryDistricts" :key="item.id" :label="item.areaName" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="联系详细地址：" prop="contactAddress">
            <div>{{ form.client.contactAddress }}</div>
          </el-form-item>
          <el-form-item label="送达详细地址：" prop="deliveryAddress">
            <div>{{ form.client.deliveryAddress }}</div>
          </el-form-item>
          <el-form-item label="注册公证处：" prop="notaryOfficeName">
            <div>{{ form.notaryOfficeName }}</div>
          </el-form-item>
          <el-form-item label="注册时间：" prop="createdDate">
            <div>{{ form.account.createdDate }}</div>
          </el-form-item>
          <div v-if="form.account.accountType == 2">
            <el-form-item label="职业：" prop="profession">
              <div>{{ form.client.profession }}</div>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </el-card>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>实名信息</span>
      </div>
      <div>
        <el-form ref="clientInfoForm" :inline="true" :model="form" size="small" label-width="210px">
          <el-form-item label="实名状态：">
            <div>{{ form.account.realNameVerified == true ? '已实名' : '未实名' }}</div>
          </el-form-item>
          <el-form-item label="实名时间：">
            <div v-if="form.account.realNameVerified">{{ form.realNameVerify.verifiedDate }}</div>
          </el-form-item>
          <el-form-item label="实名名称：">
            <div>{{ form.account.realNameVerified == true ? form.realNameVerify.realName : '' }}</div>
          </el-form-item>
          <el-form-item label="联系人：">
            <div>{{ form.account.realNameVerified == true ? '' : '' }}</div>
          </el-form-item>
          <el-form-item label="对公账户开户行：">
            <div>{{ form.account.realNameVerified == true ? form.openingBankName : '' }}</div>
          </el-form-item>
          <el-form-item label="企业对公账户：">
            <div>{{ form.account.realNameVerified == true ? form.account.bankAccount : '' }}</div>
          </el-form-item>
          <el-form-item label="当事人证件类型：">
            <div>{{ form.account.realNameVerified == true ? userCertType(form.realNameVerify.certType) : '' }}</div>
          </el-form-item>
          <el-form-item label="证件号：">
            <div>{{ form.account.realNameVerified == true ? form.realNameVerify.certNo : '' }}</div>
          </el-form-item>
          <el-form-item label="证件照片：">
            <div>{{ form.account.realNameVerified == true ? form.account.createdDate : '' }}</div>
          </el-form-item>
        </el-form>
      </div>
      <update-form ref="updateForm" :user-cert-type-dict="userCertTypeDict" />
    </el-card>
  </div>
</template>
<script>
import { getClientDetails } from '@/api/login'
import { get as dict } from '@/utils/dict'
import UpdateForm from './UpdateForm'
import { findSubAreas } from '@/api/area'

export default {
  filters: {
    secretLize: function (value) {
      if (!value) return ''
      value = value.substr(0, 6) + '****' + value.substr(value.length - 4, value.length - 1)
      return value
    },
    dateFormat: function (value) {
      if (!value) return ''
      const dt = new Date(value)
      const y = dt.getFullYear()
      const m = (dt.getMonth() + 1 + '').padStart(2, '0')
      const d = (dt.getDate() + '').padStart(2, '0')
      return `${y}-${m}-${d}`
    }
  },
  components: { UpdateForm },
  props: {},
  data() {
    return {
      loading: false,
      dialog: false,
      userCertTypeDict: [],
      form: {
        notaryOfficeName: '',
        realNameVerify: {},
        account: {},
        client: {},
        bankAccount: '',
        openingBankName: '',
        picture: []
      },
      legalPerson: {},
      authAuditStatus: [],
      authTypes: [],
      // userCertTypes: [],
      //省
      provinces: [],
      //市
      contactCities: [],
      deliveryCities: [],
      //区/县
      contactDistricts: [],
      deliveryDistricts: []
    }
  },
  computed: {
    userCertType() {
      return (val) => {
        const data = this.userCertTypeDict.filter((item) => item.value == val)
        return data[0]?.label
      }
    }
  },
  watch: {
    'form.client.contactAddressProvince'(val) {
      if (val) {
        findSubAreas(this.form.client.contactAddressProvince)
          .then((res) => {
            this.contactCities = res.data
          })
          .catch(() => {})
      } else {
        this.contactCities = []
      }
    },
    'form.client.contactAddressCity'(val) {
      if (val) {
        findSubAreas(this.form.client.contactAddressCity)
          .then((res) => {
            this.contactDistricts = res.data
          })
          .catch(() => {})
      } else {
        this.contactDistricts = []
      }
    },
    'form.client.deliveryAddressProvince'(val) {
      if (val) {
        findSubAreas(this.form.client.deliveryAddressProvince)
          .then((res) => {
            this.deliveryCities = res.data
          })
          .catch(() => {})
      } else {
        this.deliveryCities = []
      }
    },
    'form.client.deliveryAddressCity'(val) {
      if (val) {
        findSubAreas(this.form.client.deliveryAddressCity)
          .then((res) => {
            this.deliveryDistricts = res.data
          })
          .catch(() => {})
      } else {
        this.deliveryDistricts = []
      }
    }
  },
  created() {
    findSubAreas(1)
      .then((res) => {
        this.provinces = res.data
      })
      .catch(() => {
        this.$notify({
          type: 'error',
          message: '加载省市数据失败'
        })
      })
    this.initDict()
    this.initData()
  },
  methods: {
    async initDict() {
      this.userCertTypeDict = await dict('USER_CERT_TYPE')
    },
    initData() {
      getClientDetails()
        .then((res) => {
          if (res.code === 0) {
            this.form = res.data
          } else {
            this.$notify({
              title: res.message,
              type: 'error',
              duration: 2500
            })
          }
        })
        .catch((err) => {
          this.loading = false
          console.error(err.response.data.message)
        })
    },
    updateInfo() {
      this.$refs.updateForm.dialog = true
      this.$refs.updateForm.updateForm = {
        id: this.form.account.id,
        realName: this.form.account.realName,
        certType: this.form.account.certType,
        certNo: this.form.account.certNo,
        phoneNumber: this.form.account.phoneNumber,
        email: this.form.account.email,
        profession: this.form.client.profession,
        contactAddressProvince: this.form.client.contactAddressProvince,
        contactAddressCity: this.form.client.contactAddressCity,
        contactAddressArea: this.form.client.contactAddressArea,
        contactAddress: this.form.client.contactAddress,
        deliveryAddressProvince: this.form.client.deliveryAddressProvince,
        deliveryAddressCity: this.form.client.deliveryAddressCity,
        deliveryAddressArea: this.form.client.deliveryAddressArea,
        deliveryAddress: this.form.client.deliveryAddress,
        notaryOfficeName: this.form.notaryOfficeName,
        accountType: this.form.account.accountType,
        relatedClientFlag: this.form.account.clientId != null && this.form.account.clientId != ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
.account-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.el-form-item div {
  /* color: red; */
  width: 330px;
}

.clearfix {
  font-size: 17px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}

.clearfix:after {
  clear: both;
}

.box-card {
  width: 1200px;
  align-content: center;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.address-form {
  /deep/.el-select:not(:first-child) {
    margin-left: 5px;
  }
}
</style>
